import React, { useState, useEffect, useContext } from "react";
import { fill, update, max, sortBy } from "lodash";
import { InputNumber } from "primereact/inputnumber";
import { css } from "@emotion/react";
import styled from "styled-components";
import { Modal, ModalBody } from "@vds/modals";
import { TextLink } from "@vds/buttons";
import { Title } from "@vds/typography";
import { Icon } from "@vds/icons";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import { DropdownSelect } from "@vds/selects";
import searchIcon from "../../assets/icons/search_blk.png";
import { SLButton } from "../../components/core/Controls";
import { MediumText, SmallText, FlexRow } from "../../components/core/Utility/index";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { getJobDuration } from "../../services/scheduler/getJobDuration";
import { getProcessingCodes } from "../../services/scheduler/getProcessingCodes";
import { jobDurationFormatter } from "../../utility/sessionData";
import JobCalculatorNotification from "./JobCalculatorNotification";
import "./JobLengthModal.css";
import { getReasonsList } from "../../services/scheduler/getReasonsList";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 85%;
  height: 2rem;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto !important;
    margin-top: 0px !important;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
  .p-inputtext {
    padding: 0px 0px 0px 15px;
  }
`;

const StyledModal = css`
  overflow: hidden;
  height: 560px;
  min-height: 560px;
  padding: 1rem;
  width: 650px;
  max-width: 100%;
  @media (max-width: 767px) {
    // width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 46px 1rem;
    // padding-right: 2px;
  }
  & span {
    padding-right: 5px;
  }
  & div:nth-of-type(2) {
    & span[class^="Wrapper"] > div {
      width: calc(100% - -36px) !important;
      height: calc(-4px + 70vh) !important;
    }
  }
  & div:nth-of-type(2) {
    overflow-y: unset;
    & span {
      & div {
        & div {
          padding-right: 5px !important;
        }
        & span:nth-of-type(2) {
          & span {
            width: 4px !important;
          }
        }
      }
    }
  }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
    }
  }
  & footer {
    padding: 1rem 0px 0px 0px;
  }
  & mark {
    background-color: transparent !important;
  }
`;
const CustomTableHeader = css`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
    }
  }
  td {
    padding: 5px 2px 5px 0px;
  }
`;

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    height: 2rem;
    width: 97%;
    & span {
      & span {
        & div {
          & select {
            padding: 0.2rem 1rem 0.2rem 0.5rem;
            font-size: 12px;
            color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
            text-wrap: wrap;
            line-height: 0.8rem;
            &:disabled {
              -webkit-text-fill-color: #000000;
              background-color: #ffffff;
              opacity: ${(props) => (props.disabled ? "0.4" : "")};
            }
          }
          & span {
            top: 0.35rem;
            right: 0.5rem;
            & svg {
              width: 0.8rem;
              height: 0.8rem;
              min-width: 0.8rem;
              min-height: 0.8rem;
              display: ${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
        & div {
          & div {
            & p {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const CustomIcon = styled.div`
  padding-top: 0.5rem;
  width: 1rem;
  height: 1rem;
  & svg {
    outline: none;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  border-bottom: 1px solid black;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

export const SLCheckBox = styled.input`
  accent-color: black;
  cursor: pointer;
  transform: scale(1.3);
`;

const CaseJobLengthModal = ({
  caseJobLengthModal,
  setCaseJobLengthModal,
  setApptLength,
  jobId,
  isNewJob,
  salesOrgId,
  setExistingJobQuantityList,
  notification,
  isAddServices,
  scheduleType,
  setProductNotesModal,
}) => {
  const { jobDurationDetails, jobLengthHours, jobLengthMinutes, accountDetails } = useContext(AccountDetailsContext);
  const [jobDurationList, setJobDurationList] = useState([]);
  const [jobItemsList, setJobItemsList] = useState([]);
  const [calculate, setCalculate] = useState(true);
  const [handle, setHandle] = useState(true);
  const [clear, setClear] = useState(true);
  const [totalJobLength, setTotalJobLength] = useState(jobLengthHours.jobLengthHours);
  const [totalJobLengthMin, setTotalJobLengthMin] = useState(jobLengthMinutes.jobLengthMinutes);
  const [isLoading, setIsLoading] = useState(false);
  const [processingCodes, setProcessingCodes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reasonsList, setReasonsList] = useState([]);

  useEffect(() => {
    fetchReasonsData();
    getJobDurationFields();
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setCaseJobLengthModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    let timerId;
    if (jobItemsList.length) {
      timerId = setTimeout(() => {
        let jobsDurationList = [...jobItemsList];
        jobsDurationList = searchText
          ? jobsDurationList.filter(
              (job) => job.materialId.includes(searchText.toLowerCase()) || job.materialName.toLowerCase().includes(searchText.toLowerCase())
            )
          : jobsDurationList;
        setJobDurationList(jobsDurationList);
      }, 100);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [searchText]);

  const fetchReasonsData = async () => {
    setIsLoading(true);
    let reasonsResponse = await getReasonsList();
    if (reasonsResponse.status === 200 && reasonsResponse.data?.data?.length) {
      const data = reasonsResponse.data?.data;
      const tempReasons = data.filter((data) => data.category === "DE_RE" && data.subCategory === "ADJUSTMENT");
      setReasonsList(tempReasons);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      notification("Unable to fetch Processing Codes due to an unexpected error.", "error");
    }
  };

  const getJobDurationFields = async () => {
    setIsLoading(true);
    let processCodesResponse = await getProcessingCodes("Install");
    if (processCodesResponse.status === 200 && processCodesResponse.data?.data?.length) {
      let processCodes = processCodesResponse.data.data.map((code) => ({ ...code, processCode: `${code.code} - ${code.description}` }));
      setProcessingCodes(processCodes);
      processCodes = processCodes.reduce((processCodesObj, code) => ({ ...processCodesObj, [code["code"]]: code }), {});
      let jobDurationList = jobDurationDetails.jobDurationDetails;
      if (jobDurationList.length) {
        jobDurationDetailsHandler(jobDurationList, processCodes);
        setIsLoading(false);
      } else {
        let response = isAddServices || isNewJob ? await getJobDuration(null, salesOrgId, null) : await getJobDuration(jobId, salesOrgId, null);
        if (response.status === 200 && response.data?.data?.length) {
          jobDurationList = response.data.data;
          jobDurationList.forEach((obj) => {
            obj.linkedMaterialLineId = null;
            if (obj.isCustomerBillable) {
              if (obj.reasonCode === null) {
                obj.isBillCustomer = true;
              } else {
                obj.isBillCustomer = false;
              }
            } else {
              obj.isBillCustomer = null;
            }
          });
          if (!isNewJob) {
            setExistingJobQuantityList(jobDurationList.filter((item) => item.quantity));
          }
          jobDurationDetails.setJobDurationDetails(jobDurationList);
          jobDurationDetailsHandler(jobDurationList, processCodes);
        } else {
          setCaseJobLengthModal(false);
          notification("Unable to fetch Job Duration Details due to an unexpected error.");
        }
        setIsLoading(false);
      }
    } else {
      setCaseJobLengthModal(false);
      notification("Unable to fetch Processing Codes due to an unexpected error.");
    }
  };

  const jobDurationDetailsHandler = (jobDurationList, processCodes) => {
    jobDurationList = sortBy(
      jobDurationList.map((job) => ({
        ...job,
        existingQuantity: job.quantity,
        materialLineId: job.materialLineId,
        processingCode: job.quantity ? job.processingCode : job.processingCode === "ZBTP" ? job.processingCode : "ZSPO",
        processCode: job.quantity ? processCodes[job.processingCode]?.processCode ?? "ZSPO - Service" : "ZSPO - Service",
        processCodeError: false,
        reasonCode: job.reasonCode,
        reasonText: job.reasonText ? job.reasonText : "Please Select",
        isBillCustomer: job.isBillCustomer,
      })),
      "materialName"
    );
    setJobDurationList(jobDurationList);
    setJobItemsList(jobDurationList);
  };

  const quantityChangeHandler = (quantity, job, index) => {
    let updatedJobList = [...jobDurationList];
    quantity = quantity > 100 || quantity === null ? 0 : quantity;
    job = update(job, "quantity", () => quantity);
    // if (!quantity && job.processCodeError) {
    //   job = update(job, "processCodeError", () => false);
    // } else if (quantity && !job.processingCode) {
    //   job = update(job, "processCodeError", () => true);
    // }
    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);
    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex((jobItem) => jobItem.materialId === job.materialId);
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && setCalculate(false);
    !handle && setHandle(true);
    clear && setClear(false);
  };

  const processingCodeHandler = (value, index, job) => {
    let updatedJobList = [...jobDurationList];
    job = update(job, "processCode", () => value);
    job = update(job, "processingCode", () => value.split(" -")[0]);
    if (job.processCodeError) {
      job = update(job, "processCodeError", () => false);
    }
    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);
    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex((jobItem) => jobItem.materialId === job.materialId);
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && handleDoneDisableBtn(totalJobLength);
    clear && setClear(false);
  };

  const checkHandler = (value, index, job) => {
    let updatedJobList = [...jobDurationList];
    job = update(job, "isBillCustomer", () => value);

    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);

    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex((jobItem) => jobItem.materialId === job.materialId);
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && handleDoneDisableBtn(totalJobLength);
    clear && setClear(false);
  };

  const reasonHandler = (value, index, job) => {
    let updatedJobList = [...jobDurationList];

    let data = reasonsList.find((reason) => reason.description.toLowerCase() === value?.toLowerCase());
    job = update(job, "reasonCode", () => data.id);
    job = update(job, "reasonText", () => value);

    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);

    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex((jobItem) => jobItem.materialId === job.materialId);
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && handleDoneDisableBtn(totalJobLength);
    clear && setClear(false);
  };

  const handleCalculate = () => {
    !calculate && setCalculate(true);
    let jobQuantityList = jobItemsList.filter((job) => job.quantity);
    let totalDurationMin = jobQuantityList.reduce(
      (totalDuration, job) => totalDuration + (job.duration !== null ? job.duration * job.quantity : 0),
      0
    );
    setTotalJobLengthMin(totalDurationMin);
    // setTotalJobLength(Math.round(totalQuantity / 60));
    if (totalDurationMin <= 60) {
      setTotalJobLength(Math.ceil(totalDurationMin / 60));
      handleDoneDisableBtn(Math.ceil(totalDurationMin / 60));
    } else {
      let hours = parseInt(totalDurationMin / 60);
      let remainingminutes = totalDurationMin % 60;
      let minutesToAdd = 0;
      if (remainingminutes > 15 && remainingminutes < 46) {
        minutesToAdd = 30;
      } else if (remainingminutes > 45 && remainingminutes < 60) {
        minutesToAdd = 60;
      }
      let totalmins = hours * 60 + minutesToAdd;
      setTotalJobLength(totalmins / 60);
      handleDoneDisableBtn(totalmins / 60);
    }
  };

  const handleDoneDisableBtn = (totalLength) => {
    if (!isLoading && totalLength > 0) {
      handle && jobItemsList.every((job) => !job.processCodeError) && setHandle(false);

      if (
        accountDetails.accountBrand !== "GSA" &&
        accountDetails.accountBrand !== "Sourcewell" &&
        accountDetails.accountBrand !== "NASPO" &&
        accountDetails.accountBrand !== "AssetWorks"
      ) {
        jobItemsList.forEach((el) => {
          if (el.isCustomerBillable) {
            if (el.quantity > 0) {
              if (!el.isBillCustomer) {
                if (el.reasonText === "Please Select") {
                  setHandle(true);
                }
              }
            }
          }
        });
      }
    } else {
      !handle && jobItemsList.some((job) => job.processCodeError) && setHandle(true);
    }
  };

  const handleDone = () => {
    setCaseJobLengthModal(false);
    setProductNotesModal(true);
    setApptLength(totalJobLength);
    jobLengthMinutes.setJobLengthMinutes(totalJobLengthMin);
    jobLengthHours.setJobLengthHours(totalJobLength);
    let jobsDurationList = [...jobItemsList];
    // let maxLineId = max(jobsDurationList.map((job) => (job.materialLineId ? Number(job.materialLineId) : 0)));
    // let materialLineId = maxLineId;
    // jobsDurationList = jobsDurationList.map((job) => {
    //   let materialUniqueId = job.materialLineId;
    //   if (job.quantity && !materialUniqueId) {
    //     materialLineId += 10;
    //     materialUniqueId = materialLineId.toString().padStart(6, "0");
    //   }
    //   return {
    //     ...job,
    //     materialLineId: materialUniqueId,
    //     existingQuantity: job.quantity,
    //   };
    // });
    setJobDurationList(jobsDurationList);
    setJobItemsList(jobsDurationList);
    jobsDurationList.forEach((item, index, array) => {
      if (index < array.length - 1) {
        const nextItem = array[index + 1];
        if (item.processingCode === "ZSPO" && nextItem.processingCode === "ZBTP") {
          nextItem.reasonCode = item.reasonCode;
          nextItem.reasonText = item.reasonText;
          nextItem.isBillCustomer = item.isBillCustomer;
        }
      }
    });
    jobDurationDetails.setJobDurationDetails(jobsDurationList);
  };

  const handleClear = () => {
    !calculate && setCalculate(true);
    !handle && setHandle(true);
    !clear && setClear(true);
    setTotalJobLength(0);
    setSearchText("");
    let jobItems = jobItemsList.map((job) => ({
      ...job,
      quantity: 0,
      processingCode: "ZSPO",
      processCode: "ZSPO - Service",
      processCodeError: false,
      reasonCode: null,
      reasonText: "Please Select",
      isBillCustomer: true,
    }));
    setJobDurationList(jobItems);
    setJobItemsList(jobItems);
  };

  return (
    <>
      {accountDetails.accountBrand === "GSA" ||
      accountDetails.accountBrand === "Sourcewell" ||
      accountDetails.accountBrand === "NASPO" ||
      accountDetails.accountBrand === "AssetWorks" ? (
        <Modal
          role="dialog"
          ariaLabel="Job Length Calculator"
          id={scheduleType === "AUTO" && totalJobLength > 10 ? "job-length-modal" : ""}
          opened={caseJobLengthModal}
          disableOutsideClick
          css={StyledModal}
          closeButton={
            <>
              <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
                <Title size="medium">Job Length Calculator</Title>
                <div
                  onClick={() => {
                    !isLoading && setCaseJobLengthModal(false);
                  }}
                  style={{ marginTop: "0.3rem" }}
                >
                  <Icon
                    aria-label="close"
                    alt="close-icon"
                    role="img"
                    name="close"
                    size="medium"
                    color="#000000"
                    style={{ cursor: "pointer", outline: "none" }}
                  />
                </div>
              </FlexRow>
            </>
          }
        >
          <ModalBody>
            <JobCalculatorNotification scheduleType={scheduleType} totalJobLength={totalJobLength} />
            <FlexRow css={{ marginTop: "1rem", justifyContent: "space-between", alignItems: "flex-end" }}>
              <SearchInputWrapper>
                <SearchInput
                  value={searchText}
                  type="text"
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="Search"
                ></SearchInput>
                <img src={searchIcon} height="20px" width="20px"></img>
              </SearchInputWrapper>
              <SmallText css={{ marginRight: "1.4rem" }}>
                <TextLink
                  role="link"
                  aria-label={totalJobLength ? `Recalculate` : `Calculate`}
                  css={{ fontSize: "12px", fontWeight: "700", fontFamily: "Verizon-NHG-eTX" }}
                  disabled={calculate}
                  onClick={handleCalculate}
                >
                  {totalJobLength ? `Recalculate` : `Calculate`}
                </TextLink>
              </SmallText>
            </FlexRow>
            <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
              <TableHead tabIndex={0} role="columnheader">
                <TableHeader tabIndex={0} role="columnheader" width="17%">
                  <SmallText bold>Quantity</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="18%">
                  <SmallText bold>MMID</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="35%">
                  <SmallText bold>Install Type</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="30%">
                  <SmallText bold>Processing Code</SmallText>
                </TableHeader>
              </TableHead>
            </Table>
            <div css={{ height: "35vh", overflowY: "scroll" }}>
              <Loader active={isLoading} fullscreen={false} />
              <Table role="table" topLine="none" striped={false} css={CustomTableHeader}>
                <TableBody role="rowgroup">
                  {jobDurationList.map((job, index) => {
                    return (
                      <TableRow role="row" key={job.materialId + index}>
                        <Cell tabIndex={0} role="cell" width="17%">
                          <InputNumber
                            value={job.quantity <= 100 ? job.quantity : 0}
                            aria-label={job.quantity <= 100 ? job.quantity : 0}
                            tabIndex={0}
                            ariaLabel="quantity"
                            min={0}
                            max={100}
                            showButtons
                            onChange={(e) => quantityChangeHandler(e.value, job, index)}
                            css={CustomisedInput}
                          ></InputNumber>
                        </Cell>
                        <Cell tabIndex={0} role="cell" width="18%">
                          <SmallText>{job.materialId}</SmallText>
                        </Cell>
                        <Cell tabIndex={0} role="cell" width="35%">
                          <SmallText>{job.materialName}</SmallText>
                        </Cell>
                        <Cell tabIndex={0} role="cell" width="30%">
                          <CustomDropdown
                            type="text"
                            value={job.processCode || "Please Select"}
                            tabIndex={0}
                            ariaLabel="processing code"
                            onChange={(e) => processingCodeHandler(e.target.value, index, job)}
                            error={job.processCodeError}
                            hideErrorImg={job.processCodeError}
                            errorText="Please select processing code"
                          >
                            <>
                              {processingCodes.map((code) => (
                                <option key={code.code}>{code.processCode}</option>
                              ))}
                            </>
                          </CustomDropdown>
                        </Cell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </ModalBody>
          <footer
            style={{ borderTop: "1px solid #000000" }}
            id={scheduleType === "AUTO" && totalJobLength > 10 ? "sticky-footer-bottom" : "sticky-footer-bottom"}
          >
            <div>
              <FlexRow css={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <SmallText tabIndex={0} bold>
                    Job Length (Hours)
                  </SmallText>
                  <MediumText tabIndex={0}>{jobDurationFormatter(totalJobLength)}</MediumText>
                </div>
                <FlexRow>
                  <SLButton
                    aria-label="Confirm"
                    role="button"
                    primary
                    style={{ paddingRight: "48px", marginRight: "20px" }}
                    disabled={handle}
                    aria-disabled={handle}
                    onClick={handleDone}
                  >
                    Confirm
                  </SLButton>
                  <SLButton
                    aria-label="Cancel"
                    role="button"
                    secondary
                    style={{ paddingRight: "48px", marginRight: "0px" }}
                    onClick={handleClear}
                    disabled={clear}
                    aria-disabled={clear}
                  >
                    Clear
                  </SLButton>
                </FlexRow>
              </FlexRow>
            </div>
          </footer>
        </Modal>
      ) : (
        <Modal
          role="dialog"
          ariaLabel="Job Length Calculator"
          id={scheduleType === "AUTO" && totalJobLength > 10 ? "job-length-modal" : ""}
          opened={caseJobLengthModal}
          disableOutsideClick
          css={StyledModal}
          closeButton={
            <>
              <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
                <Title size="medium">Job Length Calculator</Title>
                <div
                  onClick={() => {
                    !isLoading && setCaseJobLengthModal(false);
                  }}
                  style={{ marginTop: "0.3rem" }}
                >
                  <Icon
                    aria-label="close"
                    alt="close-icon"
                    role="img"
                    name="close"
                    size="medium"
                    color="#000000"
                    style={{ cursor: "pointer", outline: "none" }}
                  />
                </div>
              </FlexRow>
            </>
          }
        >
          <ModalBody>
            <JobCalculatorNotification scheduleType={scheduleType} totalJobLength={totalJobLength} />
            <FlexRow css={{ marginTop: "1rem", justifyContent: "space-between", alignItems: "flex-end" }}>
              <SearchInputWrapper>
                <SearchInput
                  value={searchText}
                  type="text"
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="Search"
                ></SearchInput>
                <img src={searchIcon} height="20px" width="20px"></img>
              </SearchInputWrapper>
              <SmallText css={{ marginRight: "1.4rem" }}>
                <TextLink
                  role="link"
                  aria-label={totalJobLength ? `Recalculate` : `Calculate`}
                  css={{ fontSize: "12px", fontWeight: "700", fontFamily: "Verizon-NHG-eTX" }}
                  disabled={calculate}
                  onClick={handleCalculate}
                >
                  {totalJobLength ? `Recalculate` : `Calculate`}
                </TextLink>
              </SmallText>
            </FlexRow>
            <Table tabIndex={0} role="table" topLine="none" css={CustomTableHeader}>
              <TableHead tabIndex={0} role="columnheader">
                <TableHeader tabIndex={0} role="columnheader" width="15%">
                  <SmallText bold>Quantity</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="17%">
                  <SmallText bold>MMID</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="26%">
                  <SmallText bold>Install Type</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="15%">
                  <SmallText bold> Bill customer</SmallText>
                </TableHeader>
                <TableHeader tabIndex={0} role="columnheader" width="26%">
                  <SmallText bold> Reason for not billing customer</SmallText>
                </TableHeader>
              </TableHead>
            </Table>
            <div css={{ height: "35vh", overflowY: "scroll" }}>
              <Loader active={isLoading} fullscreen={false} />
              <Table role="table" topLine="none" striped={false} css={CustomTableHeader}>
                <TableBody role="rowgroup">
                  {jobDurationList.map((job, index) => {
                    return (
                      <>
                        {job.processingCode === "ZSPO" && (
                          <TableRow role="row" key={job.materialId + index}>
                            <Cell tabIndex={0} role="cell" width="15%">
                              <InputNumber
                                value={job.quantity <= 100 ? job.quantity : 0}
                                aria-label={job.quantity <= 100 ? job.quantity : 0}
                                tabIndex={0}
                                ariaLabel="quantity"
                                min={0}
                                max={100}
                                showButtons
                                onChange={(e) => quantityChangeHandler(e.value, job, index)}
                                css={CustomisedInput}
                              ></InputNumber>
                            </Cell>
                            <Cell tabIndex={0} role="cell" width="17%">
                              <SmallText>{job.materialId}</SmallText>
                            </Cell>
                            <Cell tabIndex={0} role="cell" width="26%">
                              <SmallText>{job.materialName}</SmallText>
                            </Cell>
                            <Cell tabIndex={0} role="cell" width="15%">
                              {job.isCustomerBillable && (
                                <SLCheckBox
                                  type="checkbox"
                                  checked={job.isBillCustomer}
                                  onChange={() => checkHandler(!job.isBillCustomer, index, job)}
                                />
                              )}
                            </Cell>

                            <Cell tabIndex={0} role="cell" width="26%">
                              {job.isCustomerBillable && (
                                <CustomDropdown
                                  type="text"
                                  value={job.reasonText || "Please Select"}
                                  tabIndex={0}
                                  ariaLabel="processing code"
                                  onChange={(e) => reasonHandler(e.target.value, index, job)}
                                  disabled={job.isBillCustomer}
                                >
                                  <>
                                    <option disabled style={{ backgroundColor: "lightgrey" }}>
                                      Please Select
                                    </option>
                                    {reasonsList.map((code) => (
                                      <option key={code.id}>{code.description}</option>
                                    ))}
                                  </>
                                </CustomDropdown>
                              )}
                            </Cell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </ModalBody>
          <footer
            style={{ borderTop: "1px solid #000000" }}
            id={scheduleType === "AUTO" && totalJobLength > 10 ? "sticky-footer-bottom" : "sticky-footer-bottom"}
          >
            <div>
              <FlexRow css={{ justifyContent: "space-between", width: "100%" }}>
                <div>
                  <SmallText tabIndex={0} bold>
                    Job Length (Hours)
                  </SmallText>
                  <MediumText tabIndex={0}>{jobDurationFormatter(totalJobLength)}</MediumText>
                </div>
                <FlexRow>
                  <SLButton
                    aria-label="Confirm"
                    role="button"
                    primary
                    style={{ paddingRight: "48px", marginRight: "20px" }}
                    disabled={handle}
                    aria-disabled={handle}
                    onClick={handleDone}
                  >
                    Confirm
                  </SLButton>
                  <SLButton
                    aria-label="Cancel"
                    role="button"
                    secondary
                    style={{ paddingRight: "48px", marginRight: "0px" }}
                    onClick={handleClear}
                    disabled={clear}
                    aria-disabled={clear}
                  >
                    Clear
                  </SLButton>
                </FlexRow>
              </FlexRow>
            </div>
          </footer>
        </Modal>
      )}
    </>
  );
};

export default CaseJobLengthModal;
