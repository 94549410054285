import { Button } from "@vds/buttons";
import { Grid, Row } from "@vds/grids";
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Body } from "@vds/typography";
import { lazy, useContext, useEffect, useState } from "react";
import { generate } from "shortid";
import { Header, SLButton, SLInput, SLDropDown } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CompactCol, FlexRow, LargeText, Spacer, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import { COLUMN_WIDTHS, OPEN_TAB_EVENT } from "../../constants/ApplicationConstants";
import { ACCOUNT_REGEX, ESN_REGEX } from "../../constants/ValidationConstants";
import { fullboxswap, salesforceCases, vtuLocalSwap } from "../../services/VTU/swapDevices";
import { resetTab, updateAddress } from "../../utility/navigation";
import { messageBus } from "../../utility/messageBus";
import { CHECKIN_PAGE } from "../pageList";
import styled from "@emotion/styled";
import ConfirmationModal from "../../components/core/ConfirmModal";
import { TabContext } from "../../context/tabContext";

const NotificationWrapper = styled(SLNotification)`
  .ldhZEP {
    max-width: 15%;
  }
`;

const SLInputWrapper = styled(SLInput)`
  height: auto;
`;
const ButtonFlexWrapper = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 38.75rem;
  min-width: 4.125rem;
`;
const gridConfig = [
  {
    key: "CASE",
    value: "CaseNumber",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "STATUS",
    value: "Status",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Date Opened",
    value: "CreatedDate",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    width: COLUMN_WIDTHS.medium,
  },
];

export default function SwapDevicesFinal({ esn, accountId, orderNumber, contractFlexFlag, detailsForESN }) {
  const [notification, setNotification] = useState();
  const [oldESN, setOldESN] = useState(esn);
  const [oldESNError, setOldESNError] = useState(false);
  const [newESN, setNewESN] = useState("");
  const [newESNError, setNewESNError] = useState(false);
  const [account, setAccount] = useState(accountId);
  const [accountError, setAccountError] = useState(false);
  const [order, setOrder] = useState(orderNumber);
  const [orderError, setOrderError] = useState(false);
  const [oldESNCases, setOldESNCases] = useState([]);
  const [newESNCases, setNewESNCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [cases, setCases] = useState(detailsForESN.oldCaseList.caseList);
  const [oldESNNotification, setOldESNNotification] = useState(cases?.length > 0 ? false : true);
  const [newESNNotification, setNewESNNotification] = useState();
  const [reason, setReason] = useState("");
  const [installType, setInstallType] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [installTypeError, setInstallTypeError] = useState(false);
  const [confirmation, setConfirmation] = useState();
  const {
    swapDevicePage: { setSwapESN },
  } = useContext(TabContext);

  const openTab = (eachSubMenu, newESN) => {
    updateAddress(`/#${eachSubMenu.route}`);
    let tab = { tabLabel: eachSubMenu.name, componentName: eachSubMenu.id, url: eachSubMenu.route };
    resetTab();
    new Promise((resolve) => {
      setTimeout(() => resolve(true), 200);
    }).then(() => {
      messageBus.trigger(OPEN_TAB_EVENT, tab);
      setSwapESN(newESN);
    });
  };

  useEffect(() => {
    if (detailsForESN.warning) notify(detailsForESN.warning, "info");
  }, []);

  const handleReasonChange = (e) => {
    if (reasonError) setReasonError(false);
    setOldESNNotification(false);
    setNewESNNotification(false);
    setReason(e.target.value);
  };

  const handleInstallTypeChange = (e) => {
    if (installTypeError) setInstallTypeError(false);
    setOldESNNotification(false);
    setNewESNNotification(false);
    setInstallType(e.target.value);
  };

  const oldESNChange = (e) => {
    if (oldESNError) setOldESNError(false);
    setOldESNNotification(false);
    setNewESNNotification(false);
    setOldESN(e.target.value);
  };

  const newESNChange = (e) => {
    if (newESNError) setNewESNError(false);
    setOldESNNotification(false);
    setNewESNNotification(false);
    setNewESN(e.target.value);
  };

  const accountChange = (e) => {
    if (accountError) setAccountError(false);
    setOldESNNotification(false);
    setNewESNNotification(false);
    setAccount(e.target.value);
  };
  const orderChange = (e) => {
    if (orderError) setOrderError(false);
    setOrder(e.target.value);
    setOldESNNotification(false);
    setNewESNNotification(false);
  };
  const notify = (content, type) => {
    setNotification(<NotificationWrapper key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const validate = () => {
    let flag = false;
    if (!oldESN.match(ESN_REGEX)) {
      setOldESNError(true);
      flag = true;
    }
    if (!newESN.match(ESN_REGEX)) {
      setNewESNError(true);
      flag = true;
    }
    if (!account.match(ACCOUNT_REGEX)) {
      setAccountError(true);
      flag = true;
    }
    if (contractFlexFlag && reason === "") {
      setReasonError(true);
      flag = true;
    }
    if (contractFlexFlag && installType === "") {
      setInstallTypeError(true);
      flag = true;
    }

    return flag;
  };

  const swapESN = async () => {
    if (cases?.length > 0) setCases([]);
    if (oldESNCases.length > 0) setOldESNCases([]);
    if (newESNCases.length > 0) setNewESNCases([]);
    setOldESNNotification(false);
    setNewESNNotification(false);
    if (validate()) {
      return;
    }
    setLoading(true);
    let payload = {
      oldESN: oldESN,
      newESN: newESN,
      universalAccountId: account,
      orderNumber: order,
      reason: reason,
      installType: installType,
      cfOrder: contractFlexFlag,
    };
    const response = await fullboxswap(payload);
    const { status, data } = response;
    if (status === 200 && data) {
      setConfirmation({
        headerText: "Success",
        bodyText: data.data,
        onContinue: () => {
          openTab(CHECKIN_PAGE, newESN);
        },
      });
    } else {
      notify(response.response.data.message, "error");
    }
    setLoading(false);
  };

  const searchSalesforceCases = async () => {
    if (cases?.length > 0) setCases([]);
    if (oldESNCases.length > 0) setOldESNCases([]);
    if (newESNCases.length > 0) setNewESNCases([]);
    setOldESNNotification(false);
    setNewESNNotification(false);
    if (validate()) {
      return;
    }
    setLoading(true);
    let newESNData = await salesforceCases(newESN);
    let oldESNData = await salesforceCases(oldESN);
    if (oldESNData.status === 200 && oldESNData?.data?.data?.caseList) {
      oldESNData = oldESNData.data.data.caseList;
      setOldESNCases(oldESNData);
      setOldESNNotification(false);
    } else {
      setOldESNNotification(true);
    }
    if (newESNData.status === 200 && newESNData?.data?.data?.caseList) {
      newESNData = newESNData.data.data.caseList;
      setNewESNCases(newESNData);
      setNewESNNotification(false);
    } else {
      setNewESNNotification(true);
    }
    setLoading(false);
  };

  const localSwap = async () => {
    if (validate()) {
      return;
    }
    setLoading(true);
    let response = await vtuLocalSwap(oldESN, newESN);
    if (response.status === 200) {
      notify("ESNs successfully swapped", "success");
    } else {
      setAlertModal(true);
    }
    setLoading(false);
  };
  return (
    <>
      <Header>Swap Devices</Header>
      {notification}
      <Loader active={loading} />
      <ConfirmationModal
        isOpen={confirmation}
        bodyText={confirmation?.bodyText}
        headerText={confirmation?.headerText}
        onContinue={() => {
          confirmation?.onContinue();
          setConfirmation(null);
        }}
      />
      <Modal opened={alertModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            We are currently experiencing difficulty in processing your request. Please try again sometime.
          </Body>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setAlertModal(false)}>OK</Button>
        </ModalFooter>
      </Modal>
      <SLGridLayout colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
        <FlexRow>
          <LargeText bold={true} css={{ padding: "10px 0px" }}>
            Input the new device&apos;s ESN
          </LargeText>
        </FlexRow>
        <SLRowLayout>
          <CompactCol css={{ paddingLeft: "10px !important" }}>
            <SLInputWrapper
              value={oldESN}
              onChange={oldESNChange}
              type="text"
              label="Old ESN"
              width="80%"
              error={oldESNError}
              errorText="Provide a valid ESN"
            />
            {detailsForESN?.oldEsnLable && <> Label: {detailsForESN.oldEsnLable} </>}
            <SLInputWrapper
              value={account}
              onChange={accountChange}
              type="text"
              label="Account#"
              width="80%"
              error={accountError}
              errorText="Please enter correct account number"
            />
            {detailsForESN?.accountName && <> {detailsForESN.accountName} </>}
            <SLInputWrapper
              value={order}
              onChange={orderChange}
              type="text"
              label="Order#"
              width="80%"
              error={orderError}
              errorText="Please enter correct order number"
            />
            <SLInputWrapper
              value={newESN}
              onChange={newESNChange}
              type="text"
              label="New ESN#"
              width="80%"
              error={newESNError}
              errorText="Provide a valid ESN"
            />
            {contractFlexFlag && (
              <>
                <FlexRow>
                  <SLDropDown
                    disabled={!contractFlexFlag}
                    value={reason}
                    label="Swap Reason"
                    type="text"
                    width="305%"
                    name="reason"
                    onChange={handleReasonChange}
                    style={{ fontSize: "1em" }}
                    error={reasonError}
                    errorText="Please Select Reason"
                  >
                    <option disabled selected hidden value=""></option>
                    <option value="Repair">Repair</option>
                    <option value="Replace">Replacement</option>
                  </SLDropDown>
                </FlexRow>

                <FlexRow>
                  <SLDropDown
                    disabled={!contractFlexFlag}
                    value={installType}
                    label="Install Type"
                    type="text"
                    width="260%"
                    name="installType"
                    onChange={handleInstallTypeChange}
                    style={{ fontSize: "1em" }}
                    error={installTypeError}
                    errorText="Please Select Install Type"
                  >
                    <option disabled selected hidden value=""></option>
                    <option value="VMI">VMI</option>
                    <option value="CMI">CMI</option>
                  </SLDropDown>
                </FlexRow>
              </>
            )}
            <ButtonFlexWrapper>
              <SLButton onClick={searchSalesforceCases}>Search Salesforce Cases</SLButton>
              <SLButton onClick={swapESN}>Swap ESN</SLButton>
              <SLButton disabled>Swap ESN & Add Peripherals</SLButton>
              <SLButton onClick={localSwap}>Local Swap</SLButton>
            </ButtonFlexWrapper>
          </CompactCol>
          <CompactCol>
            <br />
            {oldESNNotification && (
              <NotificationWrapper key={generate()} type="error" title={`Cases for Old ESN ${oldESN} not found`} fullBleed={true} />
            )}
            {(cases?.length > 0 || oldESNCases.length > 0) && (
              <Spacer top="20px" bottom="40px">
                <GridComponent
                  css={{ padding: "50px 0px" }}
                  noDataMessage="No data found"
                  rows={cases?.length > 0 ? cases : oldESNCases}
                  columns={gridConfig}
                  title={"SF Cases for Old ESN " + oldESN}
                />
              </Spacer>
            )}
            <br />
            {newESNNotification && (
              <NotificationWrapper key={generate()} type="error" title={`Cases for New ESN ${newESN} not found`} fullBleed={true} />
            )}
            {newESNCases.length > 0 && (
              <GridComponent
                css={{ padding: "50px 0px" }}
                noDataMessage="No data found"
                rows={newESNCases}
                columns={gridConfig}
                title={"SF Cases for New ESN " + newESN}
              />
            )}
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
    </>
  );
}

export const SwapDevicesMetadata = {
  name: "Swap Devices Confirmation",
  id: "swap_devices_final",
  component: lazy(() => import("./SwapDevicesFinal")),
  route: "/swap_devices",
};
