import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";
import styled from "styled-components";
import searchIcon from "../../assets/icons/search_blk.png";
import { Dropdown } from "primereact/dropdown";
import { SLButton } from "../../components/core/Controls";
import Icon from "@vds/icons";
import { StyledModal } from "./RescheduleModal";
import moment from "moment";
import { FlexRow, SmallText, Spacer } from "../../components/core/Utility/index";
import { SLNotification } from "../../components/core/utility";
import { TextArea, Input } from "@vds/inputs";

import { Title } from "@vds/typography";
import { CustomisedInput } from "./CompleteJob";
import { generate } from "shortid";
import { css } from "@emotion/react";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { TextLink } from "@vds/buttons";
import { Loader } from "@vds/loaders";
import { C4SContext } from "../../context/c4sContext";
import { handleAddPage } from "../C4S Decommission - Replacement/utils";
import { AlternateManualEntryMetadata } from "./AlternateManualSchedule";

export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  border: 1px solid grey;
  width: 90%;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 90%;
  padding: 10px;
  margin-right: 1rem;
`;

export const GlobalFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 0 15px;
  height: 30px;
  border-bottom: 1px solid black;
  margin: 0rem;
  input {
    outline: none;
    border: none;
    height: 25px;
    width: 75%;
  }
  img {
    width: 35px;
  }
`;
const CustomTableHeader = css`
  // width: 95%;
  //margin-left: 0.5rem;
  margin-bottom: 0px;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0.4rem;
    }
  }
  td {
    padding: 5px 5px 5px 0px;
  }
`;

const StyledCheckbox = styled(Input)`
  // font-weight: 100;
  font-size: 12px !important;
  /* align-self: center; */
`;

const AvailableInstallersModal = ({ installerData, setAvailableInstallersModal, setSelectedInstaller, salesOrg }) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [installerList, setInstallerList] = useState([]);
  const [searchInstallerList, setSearchInstallerList] = useState([]);
  const [viewAllSalesOrgCheckboxSelected, setViewAllSalesOrgCheckboxSelected] = useState(false);

  useEffect(() => {
    if (installerData.length > 0) {
      let installerList = [...installerData];
      if (salesOrg !== "" && salesOrg !== undefined) {
        installerList = installerList.filter((installer) => installer.salesOrg === salesOrg);
        setInstallerList(installerList);
        setSearchInstallerList(installerList);
      } else {
        setInstallerList(installerList);
        setSearchInstallerList(installerList);
      }
    }
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setAvailableInstallersModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    let timerId;
    if (searchInstallerList.length > 0) {
      timerId = setTimeout(() => {
        let installerListSearch = [...searchInstallerList];
        installerListSearch = searchText
          ? installerListSearch.filter((installer) => installer.installerName.toLowerCase().includes(searchText.toLowerCase()))
          : installerListSearch;
        setInstallerList(installerListSearch);
      }, 100);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [searchText]);

  const handleInstallerData = (viewAllSalesOrgCheckboxSelected) => {
    if (installerData.length > 0) {
      let installerList = [...installerData];
      if (viewAllSalesOrgCheckboxSelected === false) {
        if (salesOrg !== "" && salesOrg !== undefined) {
          installerList = installerList.filter((installer) => installer.salesOrg === salesOrg);
          setInstallerList(installerList);
          setSearchInstallerList(installerList);
        }
      } else {
        setInstallerList(installerList);
        setSearchInstallerList(installerList);
      }
    }
  };

  useEffect(() => {
    setSearchText("");
    handleInstallerData(viewAllSalesOrgCheckboxSelected);
  }, [viewAllSalesOrgCheckboxSelected]);

  return (
    <Modal
      role="dialog"
      ariaLabel={`Available Installers`}
      hideCloseButton={false}
      opened={true}
      disableOutsideClick
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">{`Qualified Installers`}</Title>

          <div onClick={() => setAvailableInstallersModal(null)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon
              role="img"
              aria-label="close"
              alt="close-icon"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <FlexRow css={{ marginTop: "0", justifyContent: "space-between", alignItems: "flex-end" }}>
          <SmallText css={{ marginBottom: "5px" }}>These are installers available for the service items selected for this job.</SmallText>
          <SearchInputWrapper>
            <SearchInput
              value={searchText}
              type="text"
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Search installer name"
            ></SearchInput>
            <img src={searchIcon} height="20px" width="20px"></img>
          </SearchInputWrapper>
        </FlexRow>
        <FlexRow>
          <Table topLine="none" css={CustomTableHeader}>
            <TableHead>
              <TableHeader width="24%">
                <SmallText bold>Installer ID</SmallText>
              </TableHeader>
              <TableHeader>
                <SmallText bold>Installer Name</SmallText>
              </TableHeader>
              <TableHeader>
                <SmallText bold></SmallText>
              </TableHeader>
              <TableHeader>
                <StyledCheckbox
                  selected={viewAllSalesOrgCheckboxSelected}
                  type="checkbox"
                  name="default"
                  width="auto"
                  onChange={() => {
                    setViewAllSalesOrgCheckboxSelected(!viewAllSalesOrgCheckboxSelected);
                  }}
                >
                  View Installers for All Sales Org
                </StyledCheckbox>
              </TableHeader>
            </TableHead>
          </Table>
        </FlexRow>
        <div css={{ height: "35vh", overflowY: "scroll !important" }}>
          <Loader active={isLoading} fullscreen={false} />
          <Table topLine="none" striped={false} css={CustomTableHeader}>
            <TableBody>
              {installerList?.map((dataRow) => (
                <TableRow key={dataRow.erpInstallerId}>
                  <Cell width="25%">
                    <SmallText>{dataRow.erpInstallerId}</SmallText>
                  </Cell>
                  <Cell colSpan={2}>
                    <SmallText>
                      {dataRow.installerName} {dataRow.salesOrg !== null ? " - " + dataRow.salesOrg : ""}
                    </SmallText>
                  </Cell>
                  <Cell>
                    <TextLink
                      css={{ fontSize: "12px" }}
                      //disabled={!calculate} onClick={handleCalculate}
                      onClick={() => {
                        //notification.setNotification(null);
                        //handleAddPage(AlternateManualEntryMetadata.id, { installData: '1234'  });
                        setSelectedInstaller({ ...dataRow });
                        setAvailableInstallersModal(false);
                      }}
                    >
                      {`Select`}
                    </TextLink>
                  </Cell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        {/* {type === "cancel" ? (
          <>
            <FlexRow justifyContent="space-between" marginBottom="8.5rem">
              <SmallText>Please Provide Reason for Abandoning Job</SmallText>
              <Dropdown
                aria-label="Please Provide Reason for Abandoning Job"
                value={initialCancelReason || "Please select"}
                options={initialCancelReasonList}
                onChange={(e) => setInitialCancelReason(e.target.value)}
                css={[CustomisedInput, { width: "71%", marginTop: "0.2rem" }]}
                scrollHeight="7.5rem"
                placeholder="Please select"
              />
            </FlexRow>
            <SmallText fontWeight="normal">You will lose any changes you made</SmallText>
          </>
        ) : type === "schedule" ? (
          <>
            {warning}
            <Spacer top="8px" />
            <SmallText fontWeight="normal">
              The job will be scheduled for .
            </SmallText>
            <Spacer top="10px" />
            <TextArea
              label="Notes To Technician"
              aria-label="Notes To Technician"
              required={true}
              helperText="Notes will be sent to the technician"
              helperTextPlacement="bottom"
              maxLength={500}
              value={notesToTech}
              onChange={(e) => handleNotes(e.target.value, notesToVendor)}
              css={{
                fontSize: "12px",
                fontFamily: "Verizon-NHG-eTX",
                letterSpacing: "0",
              }}
            />
            <Spacer top="10px" />
            <TextArea
              label="Notes To Vendor"
              aria-label="Notes To Vendor"
              required={true}
              helperText="Notes will be sent to the vendor"
              helperTextPlacement="bottom"
              maxLength={500}
              value={notesToVendor}
              onChange={(e) => handleNotes(notesToTech, e.target.value)}
              css={{
                fontSize: "12px",
                fontFamily: "Verizon-NHG-eTX",
                letterSpacing: "0",
              }}
            />
          </>
        ) : (
          <>
            <SmallText fontWeight="normal">
              The job will be rescheduled for 
            </SmallText>
          </>
        )} */}
      </ModalBody>
      {/* <ModalFooter> */}
      {/* <FlexRow css={{ justifyContent: "flex-end" }}>
        <SLButton
          css={{ width: "10rem", marginLeft: "auto" }}
          size="large"
          aria-label={type === "cancel" ? `Yes` : `Confirm`}
          role="button"
          disabled={type !== "cancel" ? disabled : false}
          aria-disabled={type !== "cancel" ? disabled : false}
          onClick={() => {
            setAvailableInstallersModal(null);
            handleSupplyNotes(type === "cancel" ? initialCancelReason : notesToTech, notesToVendor);
          }}
        >
          {type === "cancel" ? `Yes` : `Confirm`}
        </SLButton>
        <SLButton
          aria-label={type === "cancel" ? `No` : `Cancel`}
          role="button"
          secondary
          css={{ width: "10rem" }}
          size="large"
          onClick={() => setAvailableInstallersModal(false)}
        >
          {type === "cancel" ? `No` : `Cancel`}
        </SLButton>
      </FlexRow> */}
      {/* </ModalFooter> */}
    </Modal>
  );
};
export default AvailableInstallersModal;
