import * as urls from "../../api/scheduler/url";
import { postRequest } from "../../../services/RestServices";

export const updateVendorMaterials = async (payload) => {
  try {
    return await postRequest(urls.UPDATE_VENDORS_MATERIALS_LIST, payload);
  } catch (err) {
    return err;
  }
};
