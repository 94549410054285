import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexRow, SmallText, MediumText } from "../../components/core/utility";
import { Icon } from "@vds/icons";

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  border-radius: 0.5rem !important;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }

  // & span {
  //   padding: 0;
  // }
`;

const ProductNotesModal = ({
  productNotesModal,
  setProductNotesModal,
  setCaseJobLengthModal,
  setCSPJobLengthModal,
  productNotesData,
  source,
  isBackButton,
  setIsBackButton,
}) => {
  const [listData, setListData] = useState([]);

  useEffect(() => {
    let data = productNotesData.filter((item) => item?.quantity > 0 && item?.productNotes);
    setListData(data);
    if (data.length === 0) setProductNotesModal(false);
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setProductNotesModal(false);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  const handleGoBack = async () => {
    setProductNotesModal(false);
    if (source.source === "case") {
      setCaseJobLengthModal(true);
    } else {
      setCSPJobLengthModal(true);
    }
  };

  return (
    <>
      <Modal
        role="dialog"
        ariaLabel="Product Notes"
        hideCloseButton={false}
        opened={productNotesModal}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div>
              <Title size="medium">Product Notes</Title>
            </div>
            {!isBackButton && (
              <div
                onClick={() => {
                  setIsBackButton(true);
                  setProductNotesModal(false);
                }}
                style={{ marginRight: "1rem", marginTop: "0.3rem" }}
              >
                <Icon
                  aria-label="close"
                  alt="close-icon"
                  role="img"
                  name="close"
                  size="medium"
                  color="#000000"
                  style={{ cursor: "pointer", outline: "none" }}
                />
              </div>
            )}
          </FlexRow>
        }
      >
        <ModalBody css={{ paddingLeft: "0", paddingRight: "20px !important", marginTop: "0.5rem" }}>
          <div css={{ height: "35vh", overflowY: "scroll" }}>
            {listData.map((item) => {
              return (
                <>
                  <MediumText bold>
                    {item.materialId} - {item.materialName}
                  </MediumText>
                  <SmallText css={{ marginBottom: "20px" }}>{item.productNotes}</SmallText>
                </>
              );
            })}
          </div>
        </ModalBody>
        {isBackButton && (
          <ModalFooter>
            <FlexRow css={{ justifyContent: "space-between", width: "100%" }}>
              <div>
                <SmallText tabIndex={0} bold></SmallText>
                <MediumText tabIndex={0}></MediumText>
              </div>
              <FlexRow>
                <CustomSLButton
                  primary
                  aria-label="Continue"
                  role="button"
                  onClick={() => {
                    setProductNotesModal(false);
                  }}
                >
                  Continue
                </CustomSLButton>
                <CustomSLButton
                  aria-label="Go Back"
                  role="button"
                  secondary
                  onClick={() => {
                    handleGoBack();
                  }}
                >
                  Go Back
                </CustomSLButton>
              </FlexRow>
            </FlexRow>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};
export default ProductNotesModal;
