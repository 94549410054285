import * as urls from "../../api/scheduler/url";
import { getRequest } from "../../../services/RestServices";

export const getVendorMaterials = async (vendorId) => {
  try {
    return await getRequest(urls.FETCH_VENDORS_MATERIALS_LIST + `/${vendorId}`);
  } catch (err) {
    return err;
  }
};
