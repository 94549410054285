import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "@emotion/styled";
import { SmallText, FlexRow, MediumText } from "../../../components/core/Utility";
import { ColumnHeader } from "../../components/core/Table/ColumnHeader";
import { FilterMatchMode, FilterOperator, FilterService } from "primereact/api";
import { css } from "@emotion/react";
import _ from "lodash";
import { Checkbox } from "primereact/checkbox";
import { CUSTOM_FILTER_TYPE } from "../../../constants/ApplicationConstants";
import { Wrapper } from "../../../components/core/Table/GridStyles";
import searchIcon from "../../assets/icons/Search.svg";
import { Button } from "@vds/buttons";

const rowsDefault = [{ row: "" }];
const colsDefault = [{ cols: "" }];

const GridWrapper = styled(Wrapper)`
  .p-column-title {
    margin-right: 0.2rem !important;
  }
  .p-sortable-column-icon {
    visibility: visible !important;
  }
  .p-disabled {
    background-color: lightgrey !important;
    border: 1px solid lightgrey !important;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border: 1px solid black;
  padding: 0.5rem;
  width: 25rem;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

export const VendorMMIDGridComponent = ({
  /** message in case of no records*/
  noDataMessage = "No data to display",
  /** data */
  rows = { rowsDefault },
  columns = { colsDefault },
  border,
  whiteSpace,
  flyOutMenu,
  resizableColumns = true,
  initialRows = rows,
  searchKeyboardValue = "",
  onSaveClick,
  updatedMMIDs,
  setUpdatedMMIDs,
  //added customFilterDropDownData as part of VZC42148-106
}) => {
  const dt = useRef(null);
  const [filters, setFilters] = useState(null);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    if (searchKeyboardValue.length > 0) {
      const value = searchKeyboardValue;
      let updatedFilters = { ...filters };
      updatedFilters["global"].value = value;
      setFilters(updatedFilters);
      setGlobalFilterValue(value);
    } else {
      initFilters();
    }
  }, [searchKeyboardValue]);

  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  useEffect(() => {
    determineSaveEnabled();
  }, [updatedRows, initialRows]);

  const determineSaveEnabled = () => {
    updatedMMIDs.length == 0 ? setSaveDisabled(true) : setSaveDisabled(false);
  };

  const initFilters = () => {
    const obj = Object.fromEntries(
      columns.map((col) => {
        if (col.isDate) {
          return [col.value, { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }];
        } else if (col.customFilterType === CUSTOM_FILTER_TYPE.dropDown || col.customFilterType === CUSTOM_FILTER_TYPE.listBox) {
          return [col.value, { value: null, matchMode: FilterMatchMode.CONTAINS }];
        } else {
          return [col.value, { operator: FilterOperator.AND, constraints: [{ value: null }] }];
        }
      })
    );
    obj["global"] = { value: null, matchMode: "customGlobalFilter" };
    setFilters(obj);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = { ...filters };
    updatedFilters["global"].value = value;
    setFilters(updatedFilters);
    setGlobalFilterValue(value);
  };

  const updateRowData = (e, rowData, field) => {
    let newRows = [...updatedRows];
    let rowToBeUpdated = newRows.find((row) => row === rowData);
    let modifiedList = [...updatedMMIDs];
    let modifiedRow = modifiedList.find((row) => row === rowData);
    if (field === "include" && e.checked === false) {
      rowToBeUpdated["include"] = e.checked;
      rowToBeUpdated["autoScheduler"] = e.checked;
      if (modifiedRow) {
        modifiedRow["include"] = e.checked;
        modifiedRow["autoScheduler"] = e.checked;
      } else {
        let updatedRow = { ...rowData };
        updatedRow["include"] = e.checked;
        updatedRow["autoScheduler"] = e.checked;
        modifiedList.push(updatedRow);
      }
    } else {
      rowToBeUpdated[field] = e.checked;
      if (modifiedRow) {
        modifiedRow[field] = e.checked;
      } else {
        modifiedList.push(rowData);
      }
    }
    setUpdatedMMIDs(modifiedList);
    setUpdatedRows(newRows);
  };

  const getBodyToDisplay = (rowData, data) => {
    let colAttributes = columns.find((col) => col.value == data.field);
    if (colAttributes.isCheckboxes) {
      return renderCheckboxes(rowData, data);
    } else {
      return renderContentForNonHyperlink(rowData, data);
    }
  };

  const renderContentForNonHyperlink = (rowData, data) => {
    return <SmallText>{data.field === "salesOrgId" ? `${rowData[data.field]} ${rowData["salesOrg"]}` : rowData[data.field]}</SmallText>;
  };
  const renderEmptyMessage = () => {
    return <SmallText>{noDataMessage}</SmallText>;
  };

  const renderCheckboxes = (rowData, data) => {
    return (
      <div
        css={css`
          padding-left: 10px;
        `}
      >
        <Checkbox
          role="checkbox"
          disabled={data.field === "autoScheduler" && rowData.include === false}
          aria-checked={data.field === "include" ? rowData.include : rowData.autoScheduler}
          checked={data.field === "include" ? rowData.include : rowData.autoScheduler}
          onChange={(e) => updateRowData(e, rowData, data.field)}
        ></Checkbox>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <FlexRow justifyContent="space-between" style={{ marginBottom: "1rem" }}>
        <SearchInputWrapper>
          <SearchInput
            role="search"
            aria-label={globalFilterValue}
            value={globalFilterValue}
            type="text"
            onChange={onGlobalFilterChange}
            placeholder="Search MMID"
          ></SearchInput>
          <img aria-label="searchIcon" role="img" src={searchIcon} height="20px" width="20px"></img>
        </SearchInputWrapper>
        <Button role="button" size="small" aria-label="saveButton" disabled={saveDisabled} aria-disabled={saveDisabled} onClick={onSaveClick}>
          Save
        </Button>
      </FlexRow>
    );
  };
  return (
    <div>
      <GridWrapper
        colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}
        autoResizeHead={true}
        fontSize="14px"
        colGutter={true}
        whiteSpace={whiteSpace}
        flyOutMenu={flyOutMenu}
        border={border}
      >
        <DataTable
          header={renderHeader()}
          sortMode="multiple"
          removableSort
          ref={dt}
          resizableColumns={resizableColumns}
          columnResizeMode={resizableColumns ? "expand" : "fit"}
          scrollable
          scrollHeight="300px"
          scrollDirection="horizontal"
          responsiveLayout="scroll"
          filterDisplay="menu"
          emptyMessage={renderEmptyMessage()}
          filters={filters}
          value={updatedRows}
          pageLinkSize={3}
          stripedRows
        >
          {
            //added it as part of VZC42148-106
            columns.map((item) => {
              return (
                <Column
                  key={item.key}
                  field={item.value}
                  columnKey={item.key}
                  body={getBodyToDisplay}
                  header={<ColumnHeader col={item.key} ariaLabel={item.ariaLabel} />}
                  style={{
                    "min-width": `${item.width || "200px"}`,
                    width: `${item.width || "200px"}`,
                    "min-height": "34px",
                    padding: "4px 0 4px 0!important",
                    backgroundColor: flyOutMenu && "white",
                    ...item.style,
                  }}
                  sortable={item.isSortable}
                  filter={item.isFilterEnabled}
                  frozen={item.isFrozen}
                ></Column>
              );
            })
          }
        </DataTable>
      </GridWrapper>
    </div>
  );
};
