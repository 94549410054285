const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK;
const BASENAME = process.env.REACT_APP_PUBLIC_URL;
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const resourceServer = `${process.env.REACT_APP_BASE_URL}`;

//New Relic configuration
const newRelicConfig = {
  allowedOrigins: process.env.REACT_APP_BASE_URL ? [process.env.REACT_APP_BASE_URL] : [],
  loaderConfig: {
    accountID: process.env.REACT_APP_NEWRELIC_ACCOUNTID,
    trustKey: process.env.REACT_APP_NEWRELIC_TRUSTKEY,
    agentID: process.env.REACT_APP_NEWRELIC_AGENTID,
    licenseKey: process.env.REACT_APP_NEWRELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEWRELIC_APPLICATIONID,
  },
  infoConfig: {
    licenseKey: process.env.REACT_APP_NEWRELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEWRELIC_APPLICATIONID,
  }
};

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email", "offline_access", "groups"],
    pkce: true,

    // Enable this to get auth event messages in console
    devMode: false,
    cookies: {
      secure: true,
    },
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    storageManager: {
      token: {
        storageTypes: ["sessionStorage"],
      },
      cache: {
        storageTypes: ["sessionStorage"],
      },
      transaction: {
        storageTypes: ["sessionStorage"],
      },
    },
    tokenManager: {
      autoRenew: true,
      secure: true,
    },
  },
  resourceServer: {
    messagesUrl: resourceServer,
  },
  app: {},

  //New relic configuration
  newRelic: newRelicConfig  
};
